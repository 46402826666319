import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {UserDTO} from '../../model/dto/user.dto';
import {map} from 'rxjs/operators';
import {SimpleTextFilter} from '../../model/filter/simple-text-filter.model';
import {getMobileToken} from 'src/app/utils/utils.static';
import {AbstractWebservice} from "./abstract.webservice";

@Injectable({
  providedIn: 'root'
})
export class SearchWebservice extends AbstractWebservice{
  entity = `search`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }

  getAllPlacesActive() {
    return this.get(`${this.root}/places/active`);
  }

  getAllSimplePlacesForActiveUser() {
    return this.get(`${this.root}/places/simple`);
  }

  getAllOrigins() {
    return this.get(`${this.root}/origine`);
  }

  getPlacesForCurrentUser() {
    return this.get(`${this.root}/user/places`);
  }

  getActivePlacesForCurrentUser() {
    return this.get(`${this.root}/user/places/active`);
  }

  getEventTypeByTypeActivite(idTypeActivite: number) {
    return this.get(`${this.root}/events/${idTypeActivite}`);
  }

  getEventTypeByTypeActiviteAndL(lIdTypeActivite: number[]) {
    return this.post(`${this.root}/events/L`, lIdTypeActivite);
  }

  getEventTypeSportByDate(date: Date) {
    return this.post(`${this.root}/events/sport`, date);
  }

  getEventTypeOther(value: SimpleTextFilter, searchYear: number) {
    return this.post(`${this.root}/events/other?searchYear=${searchYear}`, value);
  }

  getActivityTypeOther() {
    return this.get(`${this.root}/events/activityType/other`);
  }

  getAllJobs() {
    return this.get(`${this.root}/jobs`);
  }

  getAddress(value: SimpleTextFilter) {
    return this.post(`${this.root}/address`, value);
  }

  getEventTypeJob(value: SimpleTextFilter, searchYear: number) {
    return this.post(`${this.root}/events/job?searchYear=${searchYear}`, value);
  }
}
